<script>
import {
  routeMapping
} from '../router'

export default {
  name: 'Home',
  data () {
    return {
      randomRoute: this.getRandomRoute()
    }
  },
  methods: {
    getRandomRoute: function () {
      const keys = Object.keys(routeMapping)
      return routeMapping[keys[keys.length * Math.random() << 0]]
    }
  }
}
</script>

<template>
  <div class="home">
    <img src="https://assets-bryceogden.s3.us-west-2.amazonaws.com/images/c%26j_june-2021_trimmed.jpeg">
    <h1>Welcome!</h1>
    <h5>
      Visit my
      "<router-link
        :to="{ name: randomRoute.route.name }"
      >
        <span>{{ randomRoute.route.name }}</span>
      </router-link>"
      page to {{ randomRoute.text }}!
    </h5>
  </div>
</template>

<style lang="less" scoped>
img {
  max-width: 300px;
  height: auto;
  border-radius: 50%;
  border: 6px solid #fff;
}

h1 {
  margin: 1rem;
}
</style>
