<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'

export default {
  name: 'App',
  components: {
    Header
  },
  data () {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<template>
  <div
    id="app"
    class="d-flex flex-column vh-100"
  >
    <Header />

    <router-view />

    <!-- Can just use footer instead of creating component like 'Header' -->
    <footer class="footer mt-auto py-3 xbg-light">
      <div class="container">
        <span class="text-muted">
          &copy; {{ year }} Bryce Ogden
        </span>
      </div>
    </footer>
  </div>
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100%;
}
</style>
