<script>
import {
  BDropdownItem,
  BNav,
  BNavItem,
  BNavItemDropdown
} from 'bootstrap-vue'
import $ from 'jquery'

export default {
  name: 'Header',
  components: {
    BDropdownItem,
    BNav,
    BNavItem,
    BNavItemDropdown
  },
  mounted () {
    // Close collapsible nav menu when selecting an option
    $('.nav-item > a.nav-link > a').on('click', function (event) {
      if ($('.navbar .navbar-toggler').attr('aria-expanded') === 'true') {
        // Click on navbar toggler button
        $('button[aria-expanded="true"]').click()
      }
    })
  },
  methods: {
    emailMe: function () {
      const name = 'bjogden123'
      const provider = 'gmail.com'
      window.open(`mailto:${name}@${provider}`, '_blank')
    }
  }
}
</script>

<template>
  <div
    id="portfolio-nav"
    class="container"
  >
    <nav
      class="navbar navbar-dark navbar-expand-md flex-wrap justify-content-center py-3 mb-4 border-bottom"
    >
      <div class="container-fluid">
        <img
          class="brand-logo"
          src="https://assets-bryceogden.s3.us-west-2.amazonaws.com/images/c%26j_june-2021_trimmed.jpeg"
        >
        <a
          href="/"
          class="
            navbar-brand
            d-flex
            align-items-center
            text-dark
            text-decoration-none
          "
        >
          <span class="fs-4 portfolio-title">
            Bryce Ogden
          </span>
        </a>

        <button
          class="navbar-toggler no-focus"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-main"
          aria-controls="navbar-main"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon" />
        </button>

        <div
          id="navbar-main"
          class="collapse navbar-collapse"
        >
          <b-nav class="nav-pills navbar-nav">
            <b-nav-item>
              <router-link
                :to="{ name: 'home' }"
              >
                Home
              </router-link>
            </b-nav-item>
            <b-nav-item>
              <router-link
                :to="{ name: 'about' }"
              >
                About
              </router-link>
            </b-nav-item>
            <b-nav-item>
              <router-link
                :to="{ name: 'projects' }"
              >
                Projects
              </router-link>
            </b-nav-item>
            <b-nav-item>
              <router-link
                :to="{ name: 'resume' }"
              >
                Resume
              </router-link>
            </b-nav-item>
            <b-nav-item-dropdown
              id="dropdown-contact"
              class="dropdown"
              text="Contact"
              right
            >
              <b-dropdown-item
                href="//www.linkedin.com/in/bryceogden"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </b-dropdown-item>
              <!-- <b-dropdown-item
                href="//instagram.com/brycejogden/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </b-dropdown-item> -->
              <b-dropdown-item
                href="//github.com/bjogden"
                target="_blank"
                rel="noopener noreferrer"
              >
                GitHub
              </b-dropdown-item>
              <b-dropdown-item
                target="_blank"
                rel="noopener noreferrer"
                @click="emailMe"
              >
                Email Me
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-nav>
        </div>
      </div>
    </nav>
  </div>
</template>

<style lang="less">
@import '../assets/global.less';

.portfolio-title {
  color: #fff;
}

#portfolio-nav {
  padding: 10px 30px 30px 30px;

  .brand-logo {
    max-width: 40px;
    height: auto;
    border-radius: 50%;
    border: 2px solid #fff;
    margin-right: 0.75rem;
  }

  // Make hamburger toggle square (40x40) to align with brand-logo 40x40
  .navbar-toggler {
    padding: 0.25rem;
  }

  .no-focus {
    outline: none;
    box-shadow: none;
  }

  .navbar-collapse {
    justify-content: flex-end;
  }

  .portfolio-title {
    font-weight: 700;
  }

  #dropdown-contact {
    & > a {
      background-color: initial;
      outline: none;
      box-shadow: none;

      // Yikes.
      -o-transition: color .75s;
      -ms-transition: color .75s;
      -moz-transition: color .75s;
      -webkit-transition: color .75s;
      transition: color .75s;
      text-decoration: none;

      // Yikes x2
      &:hover {
        color: @reddish;
      }

      & > span {
        color: @brownish;

        // Yikes x3
        &:hover {
          color: @reddish;
        }
      }
    }
  }

  .nav-link {
    font-weight: bold;
    color: #fff; // #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }

  @media screen and (max-width: 767px) {
    .brand-logo {
      margin-right: 1.2rem;
    }
  }
}
</style>
